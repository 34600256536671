// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    & {
        height: remify(297, 16);
    }
    .widget_title {
        font-size: remify(30, 16);
    }
}
