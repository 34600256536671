// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_inner {
    & {
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

.footer_title {
    & {
        font-size: remify(16, 16);
        text-align: left;
    }
}

.footer_text {
    & {
        text-align: left;
    }
}

.footer_right {
    & {
        text-align: left;
    }
}

.footer_right_text {
    & {
        padding-top: remify(24, 16);
        padding-left: remify(24, 16);
    }
}

.footer_menu-list_container {
    .menu-list.-sitemap {
        margin-bottom: remify(4, 16);
    }
}

.footer_logo {
    & {
        margin: 0;
        width: remify(127, 16);
    }
}

.footer_row > [class*="col-"] {
    margin-bottom: 0;
}